import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslations } from 'next-intl';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import TagManager from 'react-gtm-module';

import { FeatureFlags } from '@/common/enums';
import BrowserLanguageAlert from '@/components/BrowserLanguageAlert';
import Header from '@/components/Header';
import { selectCurrentUser } from '@/features/auth/authSlice';

export interface IPrimaryLayout extends React.ComponentPropsWithoutRef<'div'> {
  justify?: 'items-center' | 'items-start';
}

const PrimaryLayout: React.FC<IPrimaryLayout> = ({ children }) => {
  const tg = useTranslations('Generic');

  const user = useSelector(selectCurrentUser);
  const { locale, defaultLocale } = useRouter();
  useEffect(() => {
    if (!user || !user.company) {
      return;
    }

    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'load',
        user: {
          user: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          email: user.email,
        },
        company: {
          name: user.company.name,
          registryNumber: user.company.registryNumber,
          country: user.company.country,
          naceCode: user.company.naceCode,
          isRequester: user.company.isRequester,
        },
        locale: locale || defaultLocale || 'en',
      },
    });
  }, [user]);

  const isLanguageBarEnabled = useFeatureFlagEnabled(FeatureFlags.browserLanguageBar);

  if (Object.keys(tg('ok')).length == 0) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Esgrid</title>
      </Head>
      <Header />
      <Grid container className="page-container" justifyContent="center">
        <Grid item xs={12} xxl={10}>
          {isLanguageBarEnabled && <BrowserLanguageAlert />}
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default PrimaryLayout;
